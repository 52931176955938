'use client';

import React from 'react';

import Link from 'next/link';
import { useParams } from 'next/navigation';

import { useTranslation } from 'hooks/useTranslation';

import Container from 'components/tailwind-ui/Container';

import { styles } from '.';

const NotFoundLayout = () => {
  const params = useParams();

  const { t } = useTranslation(params?.lang as string);

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <div className={styles.head}>
            <h1 className={styles.title}>404.</h1>
            <p className={styles.description}>
              {t('layout.notFound.description')}
            </p>
          </div>
          <Link href={`/${params?.lang}`}>
            <div className={styles.button.container}>
              <p className={styles.button.label}>
                {t('layout.notFound.button')}
              </p>
            </div>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default NotFoundLayout;
